@import "../../../styles/helpers";

.container {
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include t {
        padding: 0; } }

.wrapper {
    padding: 80px;
    border-radius: 24px;
    background: $n7;
    @include w {
        padding: 64px 40px; }
    @include t {
        border-radius: 0; }
    @include m {
        padding: 64px 32px; } }


.title {
    margin-bottom: 12px; }

.info {
    margin-bottom: 64px;
    @include d {
        margin-bottom: 48px; } }

.sorting {
    display: flex;
    align-items: center;
    margin-bottom: 48px;
    @include t {
        justify-content: space-between; }
    @include m {
        display: block; } }

.nav {
    display: flex;
    justify-content: center;
    margin-right: auto;
    @include t {
        display: none; } }

.link {
    display: flex;
    padding: 6px 12px;
    border-radius: 14px;
    background: {}
    @include button-2;
    color: $n4;
    transition: all .2s;
    svg {
        margin-right: 8px;
        fill: $n4;
        transition: fill .2s; }
    &:hover {
        color: $n3;
        svg {
            fill: $n3; } }

    &.active {
        background: $p1;
        color: $n8;
        svg {
            fill: $n8; } }

    &:not(:last-child) {
        margin-right: 16px; } }

.box {
    flex-shrink: 0;
    width: 256px;
    @include m {
        width: 100%;
        &:not(:last-child) {
            margin-bottom: 16px; } } }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: -32px -16px 0;
    @include x {
        margin: -32px -10px 0; }
    @include d {
        margin: -32px -16px 0; }
    @include m {
        display: block;
        margin: 0; } }

.card {
    flex: 0 0 calc(25% - 32px);
    width: calc(25% - 32px);
    margin: 32px 16px 0;
    @include x {
        flex: 0 0 calc(25% - 20px);
        width: calc(25% - 20px);
        margin: 32px 10px 0; }
    @include d {
        flex: 0 0 calc(33.333% - 32px);
        width: calc(33.333% - 32px);
        margin: 32px 16px 0; }
    @include t {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); }
    @include m {
        display: block;
        width: 100%;
        margin: 32px 0 0; } }


.btns {
    margin-top: 64px;
    text-align: center;
    @include t {
        margin-top: 48px; } }

.allBrandsShowedButton {
    background-color: $p1;
    margin: 32px auto;
    width: 240px;
    color: white;
    font-size: 14px;
    padding: 8px 14px;
    text-align: center;
    border-radius: 12px; }

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px; }

.loader {
    margin-right: 20px; }

.buttonStroke {
  background: $p1;
  box-shadow: 0 0 0 2px $p1 inset;
  color: white;
  svg {
    fill: $n2; }

  &:hover,
  &.active {
    background: red;
    box-shadow: 0 0 0 2px $p1 inset;
    color: $n8;
    svg {
      fill: $n8; } } }

