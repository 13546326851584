@import "../../styles/helpers";

.location {
    position: relative;
    height: 96px;
    width : 100%;
    overflow: hidden;
    @include t {
        height: 64px; }
    &.active {
        overflow: visible;
        z-index: 5;
        .head {
            width: 100%;
            box-shadow: 0px 32px 32px 0 rgba(15, 15, 15, 0.08);
            background: $n8;
            @include d {
                width: 420px; }
            @include t {
                width: 100%;
                box-shadow: none;
                background: none; } }

        .input {
            padding-right: 64px;
            @include t {
                padding-right: 40px; } }
        .clear {
            visibility: visible;
            opacity: 1; }
        .body {
            visibility: visible;
            opacity: 1; } } }

.head {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 16px;
    user-select: none;
    transition: box-shadow .2s, background .2s; }

.input {
    width: 100%;
    height: 100%;
    padding: 0 24px 24px 0px;
    border-radius: 16px;
    background: none;
    @include text-overflow;
    @include poppins;
    @include body-bold-1;
    color: $n2;
    cursor: pointer;
    @include d {
        padding-left: 48px;
        font-size: 18px; }
    @include t {
        padding: 0 24px 16px 0; }
    @include m {
        padding: 0 0 16px 32px; }
    @include placeholder {
        color: $n2; } }


.icon {
    position: absolute;
    top: 20px;
    left: 16px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    pointer-events: none;
    @include d {
        left: 10px; }
    @include t {
        display: none; }
    @include m {
        top: 8px;
        left: 0;
        display: flex;
        width: 24px; }
    svg {
        fill: $n5;
        @include d {
            width: 20px;
            height: 20px; } } }

.description {
    position: absolute;
    left: 0px;
    bottom: 20px;
    pointer-events: none;
    @include body-2;
    color: $n4;
    @include d {
        left: 48px;
        font-size: 14px; }
    @include t {
        bottom: 4px;
        left: 0; }
    @include m {
        left: 32px; } }

.clear {
    position: absolute;
    top: 20px;
    right: 20px;
    width: 32px;
    height: 32px;
    opacity: 0;
    visibility: hidden;
    @include t {
        right: 8px;
        top: 8px; }
    @include m {
        right: 0; }
    svg {
        fill: $n5;
        transition: fill .2s; }
    &:hover {
        svg {
            fill: $p1; } } }

.body {
    max-height: 220px;
    overflow-y: scroll;
    position: absolute;
    left: 0;
    bottom: calc(100% + 16px);
    width: 510px;
    padding: 8px;
    box-shadow: 0px 40px 32px -24px rgba(15, 15, 15, 0.12);
    border-radius: 24px;
    background: $n8;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include d {
        width: 420px; }
    @include t {
        bottom: 100%;
        left: -16px;
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.12); }
    @include m {
        right: -16px;
        width: auto;
        padding: 8px; } }


.list {
    max-height: 280px;
    overflow: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
    -webkit-overflow-scrolling: touch;
    @include m {
        max-height: 222px; }
    &::-webkit-scrollbar {
        display: none; } }

.item {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 56px;
    cursor: pointer;
    border-radius: 12px;
    @include text-overflow;
    @include body-bold-2;
    color: $n4;
    transition: all .2s;
    @include d {
        min-height: 48px;
        font-size: 14px; }
    @include m {
        display: block;
        min-height: auto;
        padding: 8px; }

    &:hover {
        background: $n7;
        color: $n2; } }


.small {
    height: 72px;
    @include t {
        height: 64px; }
    .input {
        padding: 0 24px 0 64px;
        font-size: 12px;
        @include d {
            padding: 0 24px 0 48px; }
        @include t {
            padding: 0 24px 0 0; }
        @include m {
            padding: 0 0 0 32px; } }
    .clear {
        top: 50%;
        transform: translateY(-50%); }
    .icon {
        @include m {
            top: 50%;
            transform: translateY(-50%); } }
    &.active {
        .input {
            padding: 0 64px;
            @include d {
                padding: 0 64px 0 48px; }
            @include t {
                padding: 0 48px 0 0; }
            @include m {
                padding: 0 40px 0 32px; } } } }

.bodyDown {
    .body {
        top: calc(100% + 16px);
        bottom: auto;
        @include m {
            top: 100%;
            bottom: auto; } } }

.imgSize {
    padding: 0.5rem;
    margin: 0.5rem;
    width: 50px;
    height: 50px; }

@media screen and (max-width: 760px) {
    input {
        font-size: 14px !important; } }
