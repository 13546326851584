@import "../../styles/helpers";

.wrap {
    overflow: hidden; }

.list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -1px -1px 0;
    @include m {
        display: block; } }

.item {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 0 0 33.333%;
    width: 33.333%;
    height: 150px;
    border-width: 0 1px 1px 0;
    border-style: solid;
    border-color: $n6;
    @include m {
        width: 100%;
        height: 112px; }

    img {
        max-width: 166px;
        max-height: 64px; } }
