@import "../../../../styles/helpers";

.card {
    border-radius: 16px;
    overflow: hidden;
    background: $n8;
    border: 2px solid white;
    color: $n2;

    &:hover {
        transform: scale(1.1);

        border: 1px solid $p1;
        .subtitle {
            color: $p1; }

        .preview {
            img {
                transform: scale(1.1); } }
        .button {
            opacity: 1; } } }


.preview {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 9px;
    padding: 14px;
    padding-top: 25px;

    img {
        width: 100px;
        height: 100px;
        object-fit: contain;
        transition: transform 1s;
        border: 2px solid white;
        border-radius: 999px;
        box-shadow: 0px 10px 4px -4px rgba(0,0,0,0.11); } }



.body {
    padding: 2px;
    text-align: center;
    padding-bottom: 25px; }


.line {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    &:not(:last-child) {
        margin-bottom: 16px;
        padding-bottom: 16px;
        border-bottom: 1px solid $n6; } }


.subtitle {
    font-weight: medium !important;
    @include body-bold-2;
    transition: color .2s; }

.location {
    @include caption-2;
    color: $n3; }


.price {
    margin-left: 16px;
    padding: 6px 8px;
    box-shadow: inset 0 0 0 2px $p4;
    border-radius: 4px;
    text-align: center;
    @include hairline-2;
    color: $p4; }

.old {
    margin-bottom: 6px;
    text-decoration: line-through;
    color: $n5; }

.date {
    margin-right: 20px;
    @include caption-2;
    color: $n4; }

.rating {
    display: flex;
    align-items: center;
    @include caption-bold-2;
    svg {
        margin-right: 4px;
        fill: $s3; } }

.imgCenter {
    display: flex;
    justify-content: center;
    width: 100px;
    height: 100px; }

.button {
    opacity: 0; }

.arrow {
    margin: 10px;
    color: red !important;
    fill: $p1; }
