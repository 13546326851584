@import "../../styles/helpers";

.control {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding: 12px 16px;
    background: white; }

.button {
    padding: 12px 16px !important;
    margin-right: auto; }


.breadcrumbs {
    margin-left: 16px; }

.buttonStroke {
  background: white;
  box-shadow: 0 0 0 2px $n6 inset;
  color: $n2;
  svg {
    fill: $n2; }

  &:hover,
  &.active {
    background: white;
    color: $n2;
    svg {
      fill: $n2; } } }



.buttonSmall {
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 14px;
  display: flex;
  align-items: center;
  gap: 12px; }

.buttonTitle {
    font-weight: bold;
    font-size: 14px; }
