[class^="button"] {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 0 24px;
  background: $p1;
  border-radius: 24px;
  @include button-1;
  text-align: center;
  color: $n8;
  transition: all .2s;
  &:hover {
    background: darken($p1, 15); }

  &.disabled {
    opacity: .5;
    pointer-events: none; }
  svg {
    fill: $n8;
    transition: all .2s; }
  &:not([class^="button-circle"]) {
    svg {
      &:first-child {
        margin-right: 15px; }
      &:last-child {
        margin-left: 15px; } } } }

.button-stroke {
  background: none;
  box-shadow: 0 0 0 2px $p1 inset;
  color: $n2;
  svg {
    fill: $n2; }

  &:hover,
  &.active {
    background: $p1;
    box-shadow: 0 0 0 2px $p1 inset;
    color: $n8;
    svg {
      fill: $n8; } } }


.button-black {
  background: $n1;
  &:hover,
  &.active {
    background: $n2; } }


.button-white {
  background: $n8;
  color: $n2;
  svg {
    fill: $n2; }
  &:hover {
    background: $n7; } }

.button-small {
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 14px; }

.button-circle-stroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px $p1 inset;
  background: transparent;
  transition: all .2s;
  svg {
      fill: $n4; }
  &:hover {
      background: $n2;
      box-shadow: 0 0 0 2px $n2 inset;
      svg {
          fill: $n8; } } }


.button-circle-stroke.button-small {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; }


.button-stroke2 {
  background: none;
  color: $n2;
  svg {
    fill: $n2; }

  &:hover,
  &.active {
    background: $p1;
    color: $n8;
    svg {
      fill: $n8; } } }


.button-small2 {
  height: 40px;
  border-radius: 20px;
  padding: 0 16px;
  font-size: 14px; }
