@import "../../styles/helpers";

.section {
    margin-top: 56.09px;
    position: relative;
    display: flex;
    align-items: center;
    min-height: 750px;
    padding: 50px 0 150px;
    @include d {
        min-height: 700px; }
    @include t {
        min-height: 600px;
        padding: 50px 0 100px; }
    @include m {
        min-height: auto;
        padding: 0; } }

.wrap {
    max-width: 440px;
    @include m {
        max-width: 100%;
        margin-bottom: 48px; } }

.title {
    margin-bottom: 32px; }

.info {
    margin-bottom: 40px;
    @include body-2;
    color: $n4; }

.form {
    max-width: 300px; }

.gallery {
    position: absolute;
    top: 0;
    right: calc(50% - 600px);
    width: 643px;
    @include d {
        width: 600px; }
    @include t {
        width: 500px; }
    @include m {
        position: relative;
        top: auto;
        right: auto;
        width: 100%; } }

.preview {
    &:nth-child(2) {
        left: 1.5%;
        bottom: 16.5%;
        width: 39.7%;
        filter: drop-shadow(0px 40px 64px rgba(15, 15, 15, 0.1)); }
    &:nth-child(3) {
        right: 10%;
        bottom: 27.5%;
        width: 30.8%;
        filter: drop-shadow(0px 40px 32px rgba(15, 15, 15, 0.12)); }
    &:not(:first-child) {
        position: absolute; }
    img {
        width: 100%; } }
