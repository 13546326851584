@import "../../../styles/helpers";

.section {
    @include d {
        margin-bottom: 40px; } }

.container {
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include t {
        padding: 0; }
    @include m {
        padding: 0 8px; } }

.preview {
    position: relative;
    overflow: hidden;
    border-radius: 24px;
    @include t {
        border-radius: 0; }
    @include m {
        border-radius: 16px 16px 0 0; }
    &:before {
        content: "";
        display: block;
        padding-bottom: 46%;
        @include m {
            display: none; } }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        @include m {
            position: static;
            height: 500px;
            object-position: 50% 100%; } } }

.wrap {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 164px 80px;
    text-align: center;
    @include d {
        padding: 116px 80px 80px; }
    @include t {
        padding: 90px 64px 64px; }
    @include m {
        padding: 72px 24px; } }

.title {
    margin-bottom: 16px;
    @include m {
        font-size: 48px; } }


.info {
    margin-bottom: 24px;
    color: $n2; }

.panel {
    margin: -77px 80px 0;
    @include w {
        margin: -77px 40px 0; }
    @include m {
        margin: -100px 8px 0; } }

.row {
    display: flex;
    @include x {
        margin: 0 -20px; }
    @include t {
        margin: 0; }
    @include m {
        display: block;
        margin: -16px 0 -8px; }
    & > div {
        &:first-child {
            flex-grow: 1; }
        &:nth-child(2) {
            flex: 0 0 300px;
            @include x {
                flex: 0 0 280px; }
            @include d {
                flex: 0 0 250px; }
            @include t {
                flex: 0 0 180px; } }
        &:nth-child(3) {
            flex: 0 0 320px;
            @include d {
                flex: 0 0 280px; }
            @include t {
                flex: 0 0 210px; } } } }
