@import "../../../../styles/helpers";

.title {
    padding-bottom: 24px;
    letter-spacing: 0%;
    border-bottom: 2px solid #E6E8EC; }

.alternativeTitle {
    margin-bottom: 8px;
    padding-bottom: 24px; }

@media screen and (max-width: 760px) {
    .title {
        margin-top: 20px; } }

.profile {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid $n6;

    span {
        margin-right: 4px;
        white-space: nowrap;
        color: $n4; } }

.avatar {
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin: 0 8px;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.name {
    @include body-bold-2; }

.parameters {
    display: flex;
    flex-wrap: wrap;
    margin: -10px 0 40px -16px; }

.parameter {
    display: flex;
    align-items: center;
    margin: 10px 0 0 16px;
    color: $n4;
    svg {
        margin-right: 8px;
        fill: $n4; } }

.content {
    padding-top: 40px;
    margin-bottom: 48px;
    @include body-2;
    color: $n4;
    p {
        &:not(:last-child) {
            margin-bottom: 24px; } } }

.info {
    margin-bottom: 32px;
    @include body-bold-1; }

.options {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    margin: -24px 0 48px;
    @include m {
        display: block;
        margin-top: 0; }
    &:before {
        content: "";
        position: absolute;
        top: 24px;
        left: 44%;
        bottom: 0;
        width: 1px;
        background: $n6;

        @include m {
            display: none; } } }

.option {
    display: flex;
    margin-top: 24px;
    color: $n4;
    svg {
        margin-right: 16px;
        fill: $n4; }
    &:nth-child(2n+1) {
        flex: 0 0 44%;
        padding-right: 40px;
        @include x {
            padding-right: 24px; }
        @include m {
            padding: 0; } }
    &:nth-child(2n) {
        flex: 0 0 56%;
        padding-left: 80px;
        @include x {
            padding-left: 24px; }
        @include m {
            padding: 0; } } }

.button {}


.alternativeBrandDesc {
    padding-right: 40px;
    h6 {
        font-size: 16px; }

    p {
        color: $n3; } }


.alternativeBrandImgCont {
    display: flex;
    align-content: center;
    justify-content: center; }


.alternativeBrandImg {

    width: 75px;
    hegiht: 75px;
    border: 2px solid white;
    box-shadow: 0px 6px 4px -4px rgba(0,0,0,0.42);
    border-radius: 9999px; }

.decision {
    background-color: $p4;
    color: white;
    padding: 8px 16px;
    font-size: 16px;
    border-radius: 10px;
    margin-right: 12px;

    &:hover {
        background: #1A9D6E; } }

.icon {
    fill: white;
    margin-right: 10px; }

.reason {
    margin-bottom: 40px !important; }

.decisionContainer {
    display: flex;
    justify-content: start;
    flex-wrap: wrap;
    gap: 15px; }

.alternativeBrandContainer {
    display: flex;
    gap: 40px;
    align-items: center;
    border: 2px solid #E6E8EC;
    border-radius: 24px;
    padding: 10px 25px; }
