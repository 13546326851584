.section {
  background-color: #fff;
}

.dark\\:bg-gray-900 {
  background-color: #1a202c;
}

.items-center {
  align-items: center;
}

.justify-between {
  justify-content: space-between;
}

.container {
  margin-right: auto;
  margin-left: auto;
}

.w-23 {
  width: 66.666667%;
}

.mx-auto {
  margin-right: auto;
  margin-left: auto;
}

.block {
  display: block;
}

.md\\:hidden {
  display: none;
}

.img {
  display: inline-block;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.lg\\:py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.px-4 {
  padding-right: 1rem;
  padding-left: 1rem;
}

.max-w-screen-md {
  max-width: 28rem;
}

.w-full {
  width: 100%;
}

.text-4xl {
  font-size: 2.25rem;
}

.tracking-tight {
  letter-spacing: -0.01562em;
}

.font-extrabold {
  font-weight: 800;
}

.text-center {
  text-align: center;
}

.text-gray-900 {
  color: #1a202c;
}

.dark\\:text-white {
  color: #fff;
}

.mb-4 {
  margin-bottom: 1rem;
}

.font-light {
  font-weight: 300;
}

.text-gray-500 {
  color: #718096;
}

.dark\\:text-gray-400 {
  color: #a0aec0;
}

.sm\\:text-xl {
  font-size: 1.25rem;
}

.space-y-8 > :not([hidden]) ~ :not([hidden]) {
  margin-top: 2rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.text-sm {
  font-size: 0.875rem;
}

.font-medium {
  font-weight: 500;
}

.dark\\:text-gray-300 {
  color: #4a5568;
}

.shadow-sm {
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.bg-gray-50 {
  background-color: #f7fafc;
}

.border {
  border: 2px solid gray;
}

.border-gray-300 {
  border-color: #e2e8f0;
}

.text-gray-900 {
  color: #1a202c;
}

.rounded-lg {
  border-radius: 0.375rem;
}

.focus\\:ring-primary-500:focus {
  outline: 2px solid #93c5fd;
  outline-offset: 2px;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.focus\\:border-primary-500:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.block {
  display: block;
}

.w-full {
  width: 100%;
}

.p-3 {
  padding: 0.625rem;
}

.dark\\:bg-gray-700 {
  background-color: #4a5568;
}

.dark\\:border-gray-600 {
  border-color: #2d3748;
}

.dark\\:placeholder-gray-400::placeholder {
  color: #cbd5e0;
}

.dark\\:text-white {
  color: #fff;
}

.dark\\:focus\\:ring-primary-500:focus {
  outline: 2px solid #93c5fd;
  outline-offset: 2px;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.dark\\:focus\\:border-primary-500:focus {
  border-color: #4299e1;
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.dark\\:shadow-sm-light {
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
}

.col-span-2 {
  grid-column: span 2 / span 2;
}

.for\\:email {
  cursor: pointer;
}

.for\\:subject {
  cursor: pointer;
}

.for\\:message {
  cursor: pointer;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}

.sm\\:col-span-2 {
  grid-column: span 2 / span 2;
}

.h-6 {
  height: 1.5rem;
}

.w-6 {
  width: 1.5rem;
}

.rows-6 {
  grid-template-rows: repeat(6, minmax(0, 1fr));
}

.cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.flex {
  display: flex;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.px-8 {
  padding-right: 2rem;
  padding-left: 2rem;
}

.text-white {
  color: #fff;
}

.rounded-lg {
  border-radius: 0.375rem;
}

.bg-blue-500 {
  background-color: #e30013;
  width: 100%;
}

.sm\\:w-fit {
  width: fit-content;
}

.hover\\:bg-blue-500:hover {
  background-color: #4299e1;
}

.focus\\:ring-4:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.focus\\:outline-none:focus {
  outline: none;
}

.focus\\:ring-primary-300:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}

.dark\\:bg-primary-600 {
  background-color: #3182ce;
}

.dark\\:hover\\:bg-primary-700:hover {
  background-color: #2c5282;
}

.dark\\:focus\\:ring-primary-800:focus {
  box-shadow: 0 0 0 3px rgba(66, 153, 225, 0.5);
}
