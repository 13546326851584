@import "../../styles/helpers";

.panel {
    position: relative;
    z-index: 5;
    padding: 40px;
    @include d {
        padding: 32px 32px 24px; }
    @include m {
        padding: 32px 20px 24px; } }




.background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    border-radius: 24px;
    box-shadow: 0px 40px 64px -32px rgba(15, 15, 15, 0.1);
    @include m {
        border-radius: 20px;
        box-shadow: 0px 16px 32px rgba(15, 15, 15, 0.1); }
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background: linear-gradient(83.59deg, #FCFCFD 36.52%, rgba(252, 252, 253, 0.83) 98.8%);
        border: 1px solid $n8;
        backdrop-filter: blur(32px);
        border-radius: 24px;
        @include m {
            border-radius: 20px; } } }


.nav {
    position: relative;
    z-index: 2;
    display: flex;
    margin-bottom: 16px;
    @include m {
        margin: 0 -20px;
        padding: 0 20px; } }

.link {
    border-bottom: 1px solid transparent;
    @include button-2;
    color: $n4;
    transition: all .2s;
    @include d {
        padding-bottom: 24px; }
    &:hover {
        color: $n2; }
    &.active {
        border-color: $n2;
        color: $n2; }

    &:not(:last-child) {
        margin-right: 16px;
        @include m {
            margin-right: auto; } } }

.body {
    position: relative;
    z-index: 3;
    display: flex !important;
    align-items: center !important; }

@media screen and (max-width: 760px) {
    .searchbar {
        border: 1px solid $n5 !important;
        border-radius: 9999px; } }

.search {
    position: absolute;
    top: 50%;
    right: 20px;
    width: 64px;
    height: 64px;
    transform: translateY(-50%);
    background: $p1;
    border-radius: 50%;
    transition: background .2s;
    @include x {
        right: 0; }

    @include d;

    @include t {
        top: auto;
        bottom: 2px;
        transform: translateY(0);
        width: 40px;
        height: 40px;
        top: 10% !important;
        right: 5%; }
    svg {
        fill: $n8; }
    &:hover {
        background: darken($p1, 5); } }

@media screen and (max-width: 760px) {
    search {
        width: 40px !important;
        height: 40px !important;
        top: 10% !important; } }

.button {
    background: $p1;
    color: $white;
    background: none;
    box-shadow: 0 0 0 2px $n6 inset;
    color: $n2;
    svg {
        fill: $n2; }

    &:hover,
    &.active {
        background: $p1;
        box-shadow: 0 0 0 2px $p1 inset;
        color: $n8;
        svg {
            fill: $n8; } } }


.active {
    background: $p1;
    color: $white; }

