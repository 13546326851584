[class^="status"] {
  display: inline-block;
  padding: 0 12px;
  background: $n6;
  border-radius: 13px;
  font-size: 12px;
  line-height: 26px;
  font-weight: 700;
  text-transform: uppercase;
  color: $n3; }


.status-black {
  background: $n2;
  color: $n8; }


.status-gray {
  background: $n7;
  color: $n4; }


