@import "../../../styles/helpers";

.dropdown {
    position: relative;
    border: 1px solid white;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 10px;
    border-radius: 10px;
    &.active {
        .head {
            color: $n2;
            svg {
                transform: rotate(180deg);
                fill: $n2; } }

        .body {
            padding: 16px;
            visibility: visible;
            opacity: 1;
            transform: translateY(0); } } }



.textDropdown {
    color: white;
    &hover {} }


.head {
    padding-left: 12px;
    display: flex;
    align-items: center;
    @include button-2;
    color: $n4;
    transition: color .2s;
    @include m {
        display: none; }
    svg {
        margin-left: 8px;
        fill: $n4;
        transition: all .2s; }
    &:hover {
        color: $n2;
        svg {
            fill: $n2; } } }


.body {
    position: absolute;
    top: calc(100% + 43px);
    left: -40px;
    width: 292px;
    border-radius: 20px;
    box-shadow: 0px 40px 32px -8px rgba(15, 15, 15, 0.12);
    background: $n8;
    visibility: hidden;
    opacity: 0;
    transform: translateY(10px);
    transition: all .3s;
    @include m {
        position: static;
        width: auto;
        margin: 0 -32px;
        padding: 0;
        border-radius: 0;
        box-shadow: none;
        background: none;
        visibility: visible;
        transform: translateY(0);
        opacity: 1; } }


.item {
    gap: 12px;
    display: flex;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border-radius: 24px;
    @include button-2;
    color: $n4;
    transition: all .2s;
    @include m {
        height: 64px;
        padding: 0 32px;
        border-radius: 0;
        @include poppins;
        font-size: 24px;
        font-weight: 600; }
    svg {
        fill: $n4;
        transition: fill .2s;
        @include m {
            display: none; } }
    &:hover {
        color: $n2 !important;
        background-color: #F4F5F6;
        svg {
            fill: $n2; } }

    &.active {
        background-color: $p1;
        color: $n6;
        @include m {
            background: none;
            box-shadow: inset 2px 0 0 $p1;
            color: $n2; }

        svg {
            fill: $n6; } } }



.imgSize {
    width: 24px;
    height: 24px; }

.icon {
    svg {
        fill: white !important; } }

.homeIcon {
    color: $p1; }

.activeHomeIcon {
    color: white; }

.activeButton {
    color: white;
    display: flex;
    gap: 12px;
    align-items: center;
    height: 48px;
    padding: 0 20px;
    border-radius: 24px;
    @include button-2;
    color: white;
    background-color: red;
    transition: all .2s;
    @include m {
        height: 64px;
        padding: 0 32px;
        border-radius: 0;
        @include poppins;
        font-size: 24px;
        font-weight: 600; }
    svg {
        fill: white;
        color: white;
        transition: fill .2s;
        @include m {
            display: none; } }
    &:hover {
        color: $n2;
        background-color: #F4F5F6;
        svg {
            fill: $n2; } }

    &.active {
        background-color: $p1;
        color: $n6;
        @include m {
            background: none;
            box-shadow: inset 2px 0 0 $p1;
            color: $n2; }

        svg {
            fill: $n6; } } }



