@import "../../styles/helpers";

.share {
    position: relative; }



.body {
    position: absolute;
    left: 50%;
    top: calc(100% + 8px);
    z-index: 2;
    width: 220px;
    transform: translateX(-50%);
    padding: 32px 16px;
    background: $n8;
    border: 1px solid $n6;
    box-shadow: 0px 32px 32px -8px rgba(31, 47, 70, 0.12);
    border-radius: 16px;
    text-align: center;
    visibility: hidden;
    opacity: 0;
    transition: all .2s;
    @include dark {
        background: $n2;
        border-color: $n3; }
    &.show {
        visibility: visible;
        opacity: 1; } }

.openUp {
    .body {
        top: auto;
        bottom: calc(100% + 8px); } }

.title {
    margin-bottom: 24px;
    font-weight: 500; }

.list {
    display: flex;
    justify-content: center; }

.link {
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        width: 48px;
        height: 48px;
        border-radius: 50%;
        background: $n6;
        transition: all .2s;
        svg {
            transition: fill .2s; }
        &:hover {
            background: $p1;
            svg {
                fill: $n8; } }
        @include dark {
            background: $n3;
            svg {
                fill: $n8; }
            &:hover {
                background: $p1;
                svg {
                    fill: $n8; } } } }
    &:not(:last-child) {
        margin-right: 24px; } }

.darkButton {
    .button {
        box-shadow: 0 0 0 2px $n3 inset;
        &.active {
            background: $n2;
            box-shadow: 0 0 0 2px $n2 inset;
            color: $n8;
            svg {
                fill: $n8; } } }
    .body {
        background: $n2;
        border-color: $n3;
        @include m {
            left: auto;
            transform: translateX(0);
            right: -32px; } }

    .title {
        color: $n8; }
    .link {
        span {
            background: $n3;
            svg {
                fill: $n8; }
            &:hover {
                background: $p1;
                svg {
                    fill: $n8; } } } } }


.buttonCircleStroke.buttonSmall {
  flex: 0 0 40px;
  width: 40px;
  height: 40px; }

.buttonCircleStroke {
  flex: 0 0 48px;
  width: 48px;
  height: 48px;
  padding: 0;
  border-radius: 50%;
  box-shadow: 0 0 0 2px $n6 inset;
  background: transparent;
  transition: all .2s;
  svg {
      fill: $n4; }
  &:hover {
      background: $n2;
      box-shadow: 0 0 0 2px $n2 inset;
      svg {
          fill: $n8; } }
  @include dark-common {
    box-shadow: 0 0 0 2px $n3 inset;
    &:hover {
      background: $n3; } } }


.buttonSmall {
  height: 40px;
  border-radius: 20px;
  padding: 0;
  font-size: 14px; }
