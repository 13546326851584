@import "../../../styles/helpers";

.section {
    background-color: black;
    @include d {
        margin-bottom: 96px; }
    @include m {
        margin-bottom: 64px; } }


.container {
    position: relative;
    max-width: 1440px;
    @include w {
        padding: 0 40px; }
    @include t {
        padding: 0; }
    @include m {
        padding: 0 8px; } }

.preview {
    position: relative;
    display: flex;
    gap: 0;
    padding-bottom: 18%;
    @include m {
        justify-content: center;
        align-items: center;
        position: static; } }

@media screen and (min-width: 1023px) and  (max-width: 1179px) {
    .preview {
        padding-bottom: 0%; } }



.wrap {
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    max-width: min-content;
    padding: 49px 170px;
    line-height: 60px;
    @include d {
        padding: 98px 80px; }
    @include t {
        padding: 80px 40px; }
    @include m {
        padding: 40px 32px;
        text-align: center;
        width: 100%; } }

.title {
    max-width: 450px;
    margin-bottom: 16px; }


.info {
    color: $n2;
    font-size: 10px;
    @include d {
        font-size: 10px; } }

.panel {
    margin: -32% 80px 0;
    @include w {
        margin: -30% 40px 0; }
    @include d {
        margin-top: -2%; }
    @include t {
        margin: -280px 16px 0; }
    @include m {
        margin: -180px 8px 0; } }

.body {
    margin-top: 24px;
    @include d {
        margin-top: 16px; } }

.row {
    display: flex;
    @include x {
        margin: 0 -20px; }
    @include t {
        margin: 0; }
    @include m {
        display: block; }
    & > div {
        &:first-child {
            flex: 0 0 300px;
            @include w {
                flex: 0 0 270px; }
            @include d {
                flex: 0 0 250px; }
            @include t {
                flex: 0 0 220px; } }
        &:nth-child(2) {
            flex-grow: 1; }
        &:nth-child(3) {
            flex: 0 0 296px;
            @include d {
                flex: 0 0 240px; }
            @include t {
                flex: 0 0 170px; } } } }


.full {
    width : 75%; }

.description {
    width: 100%;
    line-height: 20px;
    letter-spacing: -1%;
    margin-top: 15px; }

.panelWrapper {
  position: relative;
  top: 120px; }

.titleWrap {
    display: flex;
    flex-direction: column;
    padding: 1px; }

.headerImages {
    width: 304px;
    height: 410px; }

.headerImagesContainer {
    padding-top: 29px;
    display: flex;
    gap: 21px;
    z-index: 1; }

@media screen and (max-width: 768px) {
    .headerImagesContainer {
        display: none !important; }
    .headerWire {
        display: none !important; }
    .headerWireRigt {
        display: none !important; } }

.headerWire {
    position: absolute;
    right: 0%;
    top: 56%;
    transform: translateX(-43%); }

.headerWireRigt {
    position: absolute;
    right: 0%;
    bottom: 0;
    transform: translateX(15%); }

@media screen and (max-width: 1178px) {
    .headerWire,
    .headerWireRigt {
        display: none; } }


@media screen and (min-width: 1178px) {
    .headerWire,
    .headerWireRigt {
        display: block !important; } }
