@import "../../styles/helpers";

.footer {
    border-top: 1px solid $n6; }


.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 80px 0 48px;
    @include m {
        display: block;
        padding-top: 34px; } }

.col {

    &:first-child {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 256px;
        @include m {
            flex-direction: row;
            width: 100%;
            margin-bottom: 48px; } }
    &:nth-child(2) {
        flex-grow: 1;
        padding: 0 16px;
        @include m {
            margin-bottom: 48px;
            padding: 0; } }
    &:nth-child(3) {
        width: 256px;
        @include m {
            width: 100%; } } }


.box {
    display: flex;
    align-items: center;
    @include button-2;
    color: $n4;
    & > span {
        margin-right: 8px; }
    svg {
        margin-right: 8px;
        fill: $n4; } }

.menu {
    display: flex;
    gap: 24px;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    @include m {
        justify-content: space-between;
        margin-top: 26px; } }


.link {
    @include button-2;
    color: $n4;
    transition: color .2s;
    @include t {
        flex: 0 0 calc(50% - 32px);
        width: calc(50% - 32px); }
    &:hover {
        color: $n2; }

    &:nth-child(3) {
        @include t {
            order: 5; } } }

.info {
    margin-bottom: 16px;
    @include hairline-2; }

.bottom {
    padding: 24px 0;
    border-top: 1px solid $n7; }


.copyright {
    @include caption-2; }




.iconContainer {
    display: flex;
    align-items: center;
    gap: 12px; }

.icon {
    width: 40px;
    height: 40px; }
.icon2 {
    width: 46px;
    height: 46px; }

.logo {
    @include m {
        display: flex;
        width: 100%;
        justify-content: center;
        align-items: center; } }
