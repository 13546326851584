@import "../../styles/helpers";

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2px 10px; }

.loader {
    margin-right: 20px; }
