@import "../../../styles/helpers";

.item {
    padding: 30px 0;
    border-radius: 16px;
    background: $n8;
    color: $n2;
    transition: all .2s;
    &:hover {
        div {
            a {
                color: $p1;
                transition: 1s; } }

        box-shadow: inset 0 0 0 1px $p1; } }

.preview {
    position: relative;
    margin-bottom: 16px;
    width: 155px;
    height: 50px;
    margin: 0 auto 16px;
    border-radius: 50%;
    overflow: hidden;
    &:before {
        content: '';
        display: block;
        padding-bottom: 100%; }
    img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%; } }


.number {
    margin-bottom: 16px; }

.title {
    font-weight: medium !important;
    margin-bottom: 16px;
    text-align: center;
    @include body-bold-2; }


.time {
    text-align: center;
    @include caption-2;
    color: $n4; }


.button {
    color: $white;
    background: $p1;
    border: none;
    font-size: 12px;
    font-family: Poppins , sans-serif;
    padding: 8px 12px 6px 12px !important; }



.row {
    display: flex;
    flex-direction: column;
    font-size: 12px;
    margin-top: 1rem; }

