@import "../../styles/helpers";

.receipt {
    width: 448px;
    padding: 32px;
    margin-right : 2rem;
    border: 1px solid $n6;
    box-shadow: 0px 64px 64px -48px rgba(15, 15, 15, 0.08);
    border-radius: 24px;
    @include d {
        width: 400px; }
    @include t {
        width: auto; }
    @include m {
        padding: 24px 16px; } }


@media screen and (max-width: 760px) {
    .receipt {
        margin-right: 0px !important;
        margin: auto; } }

.head {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 22px; }

.cost {
    display: flex;
    margin-bottom: 8px; }

.old,
.actual {
    @include dm-sans;
    font-size: 32px;
    line-height: 40px;
    letter-spacing: -0.01em;
    font-weight: 700; }

.old {
    margin-right: 12px;
    text-decoration: line-through;
    color: $n5; }

.note {
    position: relative;
    top: -2px;
    align-self: flex-end;
    margin-left: 9px;
    @include body-2;
    color: $n4; }

.avatar {
    position: relative;
    flex-shrink: 0;
    width: 64px;
    height: 64px;
    margin-left: auto;
    img {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        object-fit: cover; } }

.check {
    position: absolute;
    top: 0;
    right: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    background: $p4;
    svg {
        fill: $n8; } }

.rating {
    display: flex;
    align-items: center;
    svg {
        margin-right: 8px;
        fill: $s3; } }

.number {
    font-weight: 500; }

.reviews {
    margin-left: 8px;
    color: $n4; }

.description {
    margin-bottom: 32px;
    padding: 8px;
    border-radius: 20px;
    background: $n7;
    @include m {
        padding: 0; }

    &.flex {
        display: flex;
        flex-wrap: wrap;
        .item {
            flex: 0 0 50%;
            &:nth-child(2n) {
                position: relative;
                &:before {
                    content: "";
                    position: absolute;
                    top: 12px;
                    left: 0;
                    bottom: 12px;
                    width: 1px;
                    background: $n6; } } } } }


.item {
    display: flex;
    align-items: center;
    padding: 12px; }

.icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 32px;
    height: 32px;
    margin-right: 12px;
    svg {
        fill: $n5; } }

.category {
    @include caption-2;
    color: $n4; }

.subtitle {
    @include body-bold-2; }

.imgc {
    display: block;
    margin: 0 auto;
    width: 100px;
    height: 100px;
    border: 2px solid white;
    border-radius: 999px;
    box-shadow: 0px 6px 4px -4px rgba(0 , 0 , 0 , 0.41); }

.flex {
    display: flex; }
