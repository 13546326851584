@import "../../styles/helpers";



.photo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh; }

.text {
    font-size: 20px;
    font-weight: bold;
    margin: 24px 0; }
