@import "../../styles/helpers";

.button {
    margin-top: 10px; }

.lowRiskStyle {
    padding: 6px 8px;
    font-size: 12px;
    line-height: 19.2px;
    font-weight: 700;
    color: rgba(255, 209, 102, 1);
    border: 1px solid rgba(255, 209, 102, 1);
    display: inline-block;
    border-radius: 8px; }

.middleRiskStyle {
    color: rgba(250, 143, 84, 1);
    padding: 6px 8px;
    font-size: 12px;
    line-height: 19.2px;
    font-weight: 700;
    border: 1px solid rgba(250, 143, 84, 1);
    display: inline-block;
    border-radius: 8px; }

.highRiskStyle {
    color: $p1;
    padding: 6px 8px;
    font-size: 12px;
    line-height: 19.2px;
    font-weight: 700;
    border: 1px solid $p1;
    display: inline-block;
    border-radius: 8px; }

