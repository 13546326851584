@import "../../../styles/helpers";

.title {
    margin-bottom: 64px;
    @include m {
        margin-bottom: 32px;
        font-size: 32px; } }

.box {
    &:not(:last-child) {
        margin-bottom: 64px;
        padding-bottom: 64px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.category {
    margin-bottom: 16px;
    @include body-bold-1; }

.item {
    &:not(:last-child) {
        margin-bottom: 24px;
        padding-bottom: 24px;
        border-bottom: 1px solid $n6;
        @include dark {
            border-color: $n3; } } }

.head {
    display: flex;
    margin-bottom: 4px; }

.subtitle {
    margin-right: auto;
    @include body-bold-2; }

.Routes {
    flex-shrink: 0;
    margin-left: 24px; }

.content {
    @include caption-2;
    color: $n4; }
