// common styles
@font-face {
  font-family: "Stolzl Display";
  font-weight: 400;
  font-style: normal;
  src: url('../../public/fonts/StolzlDisplay-Regular.ttf') format('truetype') /* Font dosya yolunu doğru şekilde belirtmelisiniz */; }

$stolzl-font: 'Stolzl Display', sans-serif;



.stolzl-text {
  font-family: $stolzl-font;
  font-size: 48px; }
.poppins-text {
    font-family: 'Poppins', sans-serif; }
body {
    min-width: 375px;
    @include poppins;
    font-size: 14px;
    line-height: (24/14);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: $n2; }



a {
    text-decoration: none; }

svg,
img {
    vertical-align: middle; }

.desktop {
    &-hide {
        @include d {
            display: none !important; } }
    &-show {
        display: none !important;
        @include d {
            display: block !important; } }
    &-text-right {
        @include d {
            text-align: right; } } }

.tablet {
    &-hide {
        @include t {
            display: none !important; } }
    &-show {
        display: none !important;
        @include t {
            display: block !important; } } }

.mobile {
    &-hide {
        @include m {
            display: none !important; } }
    &-show {
        display: none !important;
        @include m {
            display: block !important; } } }
