@import "../../styles/helpers";

.inner {
    position: relative; }

.head {
    margin-bottom: 80px;
    padding-right: 120px;
    @include d {
        margin-bottom: 64px; }
    @include m {
        margin-bottom: 40px;
        padding: 0; } }

.headSmall {
    margin-bottom: 48px;
    @include m {
        margin-bottom: 40px; } }

.info {
    margin-top: 12px; }

.wrapper {
    margin: 0 -16px;
    @include m {
        margin: 0 -8px; } }

.hoverClass {
    &:hover {
        border: 2px solid red !important;
        color: red !important; } }

.bg {
    background-color : $n7;
    padding-top: 24px;
    padding-bottom: 24px; }


